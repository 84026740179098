.romania-map-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .romania-map-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .romania-map-svg-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .romania-map-svg {
    width: 80%;
    height: auto;
    max-height: 500px;
  }
  
  .romania-map-path {
    fill: rgb(52, 152, 219);
    cursor: pointer;
    transition: fill 0.2s ease-in-out;
  }
  
  .romania-map-path:hover {
    fill: #6c5ce7;
  }
  
  .romania-map-path.selected {
    fill: rgb(231, 76, 60);
  }
  
  .romania-map-details {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: inline-block;
    font-size: 1rem;
  }
  