/* Footer.css */
.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #3949ab;
  color: white;
  padding: 20px;
  text-align: left;
}

.footer-column {
  flex: 1;
  min-width: 200px;
  margin: 10px;
}

.footer-logo {
  height: 60px;
  margin-bottom: 10px;
}

.footer-description {
  margin-top: 10px;
  font-size: 14px;
}

.footer-links,
.footer-legal {
  list-style: none;
  padding: 0;
}

.footer-links li,
.footer-legal li {
  margin-bottom: 10px;
}

.footer-links a,
.footer-legal a {
  color: white;
  text-decoration: none;
}

.footer-links a:hover,
.footer-legal a:hover {
  text-decoration: underline;
}

.footer-title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.footer-map {
  margin-bottom: 10px;
}

.footer-address {
  font-size: 14px;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    flex: 1 1 100%;
    text-align: center;
  }
}