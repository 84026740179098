.login-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f4f6;
  }
  
  .login-card {
    width: 100%;
    max-width: 400px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
  }
  
  .login-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
  }
  
  .login-subtitle {
    font-size: 0.9rem;
    color: #666;
    margin-top: 5px;
  }
  
  .login-error {
    color: #e53e3e;
    font-size: 0.8rem;
    margin-top: 10px;
  }
  
  .login-form {
    margin-top: 20px;
  }
  
  .input-group {
    position: relative;
    margin-bottom: 15px;
  }
  
  .input-icon {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    color: #9ca3af;
  }
  
  .login-input {
    width: 100%;
    padding: 10px 40px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 0.9rem;
    color: #333;
    background: #f9fafb;
    transition: border-color 0.3s ease;
  }
  
  .login-input:focus {
    outline: none;
    border-color: #2563eb;
  }
  
  .forgot-password {
    text-align: right;
    margin-bottom: 20px;
  }
  
  .forgot-password-link {
    font-size: 0.8rem;
    color: #2563eb;
    text-decoration: none;
  }
  
  .forgot-password-link:hover {
    text-decoration: underline;
  }
  
  .login-button {
    width: 100%;
    padding: 10px;
    background: #2563eb;
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .login-button:hover {
    background: #1d4ed8;
  }
  
  .divider {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }
  
  .line {
    flex: 1;
    height: 1px;
    background: #ddd;
  }
  
  .divider-text {
    padding: 0 10px;
    font-size: 0.8rem;
    color: #666;
  }
  
  .create-account {
    margin-top: 10px;
  }
  
  .create-account-text {
    font-size: 0.9rem;
    color: #666;
  }
  
  .create-account-link {
    color: #2563eb;
    text-decoration: none;
    font-weight: bold;
  }
  
  .create-account-link:hover {
    text-decoration: underline;
  }
  