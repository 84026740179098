/* AuctionList.css */

.auction-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adaugă o umbră subtilă */
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  background-color: #f1f5f9; /* Culoare de fundal elegantă */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); /* Umbra pentru paginare */
}

.pagination button {
  background-color: #007BFF;
  color: white;
  padding: 10px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination span {
  font-size: 16px;
  font-weight: bold;
}

.pagination select,
.pagination input {
  padding: 8px 12px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  color: #333;
  text-align: center;
  width: 50px; /* Specific pentru inputul de pagină */
}

hr.divide-black {
  border: 1px solid #e5e7eb; /* Culoare gri deschis pentru o separare subtilă */
}
