.auction-item {
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.auction-item:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.auction-header {
  text-align: left;
  margin-bottom: 12px;
}

.auction-id {
  font-size: 1.2rem;
  color: #3f51b5;
}

.auction-details {
  margin-bottom: 12px;
}

.auction-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
}

.auction-authority {
  font-size: 1rem;
  color: #666;
}

.auction-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  color: #777;
  flex-wrap: wrap;
}

.auction-value span {
  font-weight: bold;
  color: #000;
}

.auction-date span {
  font-weight: bold;
  color: #000;
}
